label.form-label {
  margin: 0.75rem 0 0.25rem;
}

button[type="submit"] {
  margin-top: 1rem;
}

.contact-info {
  margin-top: 1rem;
  @media (min-width: 768px) {
    margin-top: 0rem;
  }

  svg {
    margin-right: 0.5rem;
    transform: scale(1.05);
  }
}
