@import "_all.scss";
@import "~bootstrap/scss/bootstrap";

body {
  color: $colors-neutral-1000;
  background-color: $colors-neutral-0;
  font-family: $font-family;
}

.App-main {
  padding-top: 1rem;
}

p,
.footer-copyright {
  color: $colors-neutral-800;
}

.footer-copyright {
  font-size: 0.75rem;
}

.btn-primary,
.btn-primary:active,
.btn-primary:visited {
  background-color: $colors-green-primary;
  border-color: $colors-green-primary;
  &:hover {
    background-color: $colors-green-secondary;
    border-color: $colors-green-secondary;
  }
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 2px;
}

.sub-text {
  font-size: 0.813rem;
  color: $colors-neutral-700;
}
