@import "../../_all.scss";

.pitch-card {
  text-align: center;
  border-radius: 0.75rem;
  position: relative;
  padding: 2rem 1rem;
  background-color: rgb(236, 242, 255);
  background: radial-gradient(100% 158.42% at 97.23% -6.47%, rgb(236, 242, 255) 15.02%, rgb(230, 245, 241) 100%);
  color: #424242;
  overflow: hidden;

  svg {
    font-size: 2.5rem;
    color: $colors-blue-primary;
  }

  .vc_custom_heading {
    font-family: "Staatliches", cursive;
    font-size: 2.5rem;
    text-wrap: pretty;
    line-height: 1.1;
  }

  .vc_custom_step{
    font-size: 1rem;
    font-weight: 450;
    span {
      display: inline-block;
      margin-right: .25rem;
      color: $colors-neutral-1000;
      font-weight: 600;
    }
  }
}
