$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1140px,
);

$colors-primary-100: #f8f9fe;
$colors-primary-200: #eef1fe;
$colors-primary-300: #dbe2fd;
$colors-primary-400: #c7d2f6;
$colors-primary-500: #9bb1f0;
$colors-primary-600: #6792f0;
$colors-primary-700: #3f73d3;
$colors-primary-800: #2557a7;
$colors-primary-900: #164081;
$colors-primary-1000: #0d2d5e;
$colors-neutral-0: #ffffff;
$colors-neutral-100: #faf9f8;
$colors-neutral-200: #f3f2f1;
$colors-neutral-300: #e4e2e0;
$colors-neutral-400: #d4d2d0;
$colors-neutral-500: #b4b2b1;
$colors-neutral-600: #949494;
$colors-neutral-700: #767676;
$colors-neutral-800: #595959;
$colors-neutral-900: #424242;
$colors-neutral-1000: #2d2d2d;

$colors-green-primary: #4aad52;
$colors-green-secondary: #488b49;
$colors-green-100: #f4fbf3;

$colors-blue-primary: #0084cc;
$colors-blue-secondary: #008aaf;

$font-family: "Open Sans", sans-serif;
$font-family-bold: "Staatliches", cursive;
