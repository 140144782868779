@import "../../_all.scss";



.service-cards {
  display: grid;
  column-gap: 1.25rem;
  row-gap: 1rem;
  grid-template-columns: 1fr;

  @media (min-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(19rem, 1fr));
  }

  .card {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border: 0;
  }

  .card-body {
    padding-inline: 0;
    padding-block-start: .5rem;
  }

  .card-title {
    font-size: 1rem;
    font-weight: 600;
  }

  .card-text {
    color: $colors-neutral-800;
    font-size: 0.875rem;
  }

  img {
    aspect-ratio: 20/12;
  }
}

.bg-image {
  padding-block: 1rem;
  @media (min-width: 768px) {
    padding-block: 2rem;
  }
}

.hero-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.hero-text-box {
  background:  #4aad52;
  background: radial-gradient(circle, rgba(49,153,58,1) 0%, rgba(52,144,60,1) 100%);
  padding-inline: 2rem;
  padding-block: 1rem;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 16px;
  border-radius: .25rem;
  margin: 1rem auto;
  max-width: 95%;
  display: grid;
  gap: .5rem;
  @media (min-width: 768px) {
    max-width: 90%;
  }
}

.hero-text {
  color: #fefefe;
  font-family: "Staatliches", cursive;
  font-weight: 400;
  font-style: normal;
  font-size: 3rem;
  margin-bottom: 0;
  line-height: 1;
  @media (min-width: 768px) {
    font-size: 4.25rem;
  }
}

.hero-sub-text {
  color: #fefefe;
  font-weight: 450;
  font-style: normal;
  margin-bottom: 0;
  line-height: 1;
  font-size: .875rem;
  @media (min-width: 768px) {
    font-size: 1rem;
  }
}

.location-block {
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
    gap: 1rem;
  }
}


.faq {
  color: $colors-neutral-800;

  h3,
  h5 {
    color: $colors-neutral-1000;
  }
}

.price-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  @media (min-width: 1160px) {
    grid-template-columns: 1fr 1fr;
  }
}


#price-list {
  scroll-margin-top: 4.5em;
}


.price-list-item {
  width: 100%;
  border-radius: 0.75rem;
  padding: 1rem;
  background-color: $colors-neutral-100;

  ul {
    list-style: none;
    padding: 0;

    li {
      margin: 0;
      padding: 0.5rem 0;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $colors-neutral-500;
      flex-direction: row;
      align-items: center;

      span {
        font-size: 0.875rem;
        font-weight: 600;
        display: block;
        padding: 0.25rem 0.5rem;
        border-radius: 0.25rem;
        background-color: $colors-blue-secondary;
        color: $colors-neutral-0;
        white-space: nowrap;
      }
    }
  }
}
