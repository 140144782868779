@import "../../_all.scss";

.coupon-card {
  background: $colors-neutral-1000;
  color: $colors-neutral-0;
  text-align: center;
  padding: 2rem 2rem;
  border-radius: 0.75rem;
  box-shadow: rgba(0, 0, 0, 0.22) 0px 6px 16px;
  position: relative;
  max-width: 100%;
  margin: 1rem auto;
  @media (min-width: 768px) {
    max-width: 20rem;
  }

  @media (min-width: 992px) {
    border: 3px solid $colors-neutral-0;
    margin-top: -45px;
  }
}

.coupon-card-bottom {
  background: $colors-neutral-1000;
  color: $colors-neutral-0;
  text-align: center;
  padding: 1.5rem 2rem;
  border-radius: .75rem;
  box-shadow: none;
  position: relative;
  max-width: 100%;
  margin: 1rem auto;

  @media (min-width: 992px) {
    border: 0;
    margin-top: 1rem;
  }
}

.logo {
  width: 80px;
  border-radius: 8px;
  margin-bottom: 20px;
}
.coupon-card h3 {
  font-family: "Staatliches", cursive;
  color: $colors-neutral-0;
  font-size: 2.25rem;
  line-height: 100%;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid $colors-green-primary;
  text-wrap: pretty;
}
.coupon-card h5 {
  padding-top: 0.5rem;
  font-size: 1rem;
  line-height: 100%;
}
.coupon-row {
  display: flex;
  align-items: center;
  margin: 25px auto;
  width: fit-content;
}
#cpnCode {
  border: 1px dashed $colors-neutral-0;
  padding: 10px 20px;
  border-right: 0;
}
#cpnBtn {
  border: 1px solid $colors-neutral-0;
  background: $colors-neutral-0;
  padding: 10px 20px;
  color: #7158fe;
  cursor: pointer;
}
.circle1,
.circle2 {
  background: $colors-neutral-0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.circle1 {
  left: -25px;
}
.circle2 {
  right: -25px;
}

.coupon-card a {
  color: $colors-neutral-0;
  text-decoration: none;
  &.active,
  &:active,
  &:focus {
    color: $colors-neutral-0;
  }
  &:hover {
    color: $colors-neutral-0;
  }
}

svg {
  margin-right: 0.5rem;
}
