@import "../../_all.scss";

.navbar {
  justify-content: start;
  gap: 1.5rem;
  color: white;
  background-color: #363636;
  align-items: center;
  padding: 0.5rem;

  img {
    height: 53px;
    padding: 0.35rem 0;
    margin: 0;
  }

  .nav-right-menu {
    border: none;
  }

  .navbar-brand{
    margin: 0;
  }

  .nav-right-menu-link {
    font-size: .785rem;
    padding: 0.5rem 1rem 0.35rem 1rem !important;
    color: $colors-neutral-0;
    background-color: $colors-green-primary;
    text-decoration: none;
    border-radius: 1.875rem;
    transition: background-color 0.15s ease-in-out;
    &.active,
    &:active,
    &:focus {
      color: $colors-neutral-0 !important;
    }
    &:hover {
      color: $colors-neutral-0;
      background-color: $colors-green-secondary;
    }
  }
}
